import React, { useState } from 'react';
import styles from './UserTable.module.scss';
import ResultsModal from '../ResultsModal';

const UserTable = ({ data }) => {
  const colorMap = {
    pass: '#00A480',
    fail: '#FF2700',
    pending: '#EF8D2B'
  };

  const [ isModalOpen, setIsModalOpen ] = useState(false);

  const { users } = data;

  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Users</th>
            <th>Date</th>
            <th>Score</th>
            <th>Status</th>
            <th>Results</th>
          </tr>
        </thead>

        <tbody>
          {users?.map((item, index) => (
            <tr key={item.userId}>
              <td className={styles.nameTd}>
                <img
                  width={34}
                  height={34}
                  src={item.avatarUrl}
                  alt="profileImage"
                />
                {item.fullName}
              </td>
              <td className={styles.dateTd}>{item.dateAnswered}</td>
              <td style={{ color: !Number(item.score) ? '#EF8D2B' : '#fff' }}>{item.score}</td>
              <td style={{ color: colorMap[item.status.toLowerCase()] }}>{item.status}</td>
              <td className={styles.actionsColumn}>
                <button onClick={() => setIsModalOpen(true)}
                >View Results</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ResultsModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
    </div>
  );
};

export default UserTable;
