export const quizData = {
  quizId: '123',
  title: 'Design Fundamentals',
  description: 'Quiz description goes here.',
  status: 'Active',
  averageTimeToComplete: 310,
  totalResponses: 40,
  questions: [
    {
      questionId: 'q1',
      questionText: 'What department you worked in?',
      questionType: 'multiple_choice',
      orderIndex: 1,
      totalResponses: 40,
      options: [
        { optionId: 'o1', optionText: 'Financing', responseCount: 20 },
        { optionId: 'o2', optionText: 'Technology', responseCount: 10 },
        { optionId: 'o3', optionText: 'Human Resources', responseCount: 6 },
        { optionId: 'o4', optionText: 'Research', responseCount: 4 }
      ]
    },
    {
      questionId: 'q2',
      questionText: 'Choose the image that describes the best way of planning a project:',
      questionType: 'image_choice',
      orderIndex: 2,
      totalResponses: 40,
      options: [
        {
          optionId: 'o5',
          optionText: 'Trees',
          optionImageUrl:
            'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1730458603/wizr%20x/d7f611fea113c02148845301a475d3bb_eohg9j.jpg',
          responseCount: 30
        },
        {
          optionId: 'o6',
          optionText: 'River',
          optionImageUrl:
            'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1728897073/Templates/remy_loz-3S0INpfREQc-unsplash_iwa1nd.jpg',
          responseCount: 10
        }
      ]
    },
    {
      questionId: 'q3',
      questionText: 'Which countries will be involved in this project?',
      questionType: 'text_input',
      orderIndex: 3,
      totalResponses: 40,
      options: []
    },
    {
      questionId: 'q4',
      questionText: 'How would you rate this experience?',
      questionType: 'multiple_choice',
      orderIndex: 4,
      totalResponses: 40,
      options: [
        { optionId: 'o7', optionText: 'Excellent', responseCount: 15 },
        { optionId: 'o8', optionText: 'Good', responseCount: 15 },
        { optionId: 'o9', optionText: 'Average', responseCount: 8 },
        { optionId: 'o10', optionText: 'Poor', responseCount: 2 }
      ]
    }
  ]
};

export const answerUsers = {
  quizId: '123',
  questionId: 'q1',
  questionText: 'What department you worked in?',
  selectedOptionId: 'o1',
  selectedOptionText: 'Financing',
  totalUsersResponded: 20,
  users: [
    {
      userId: 'u101',
      fullName: 'John Doe',
      dateAnswered: '2024-03-03',
      answerResult: 'Correct answer'
    },
    {
      userId: 'u102',
      fullName: 'Jane Smith',
      dateAnswered: '2024-03-03',
      answerResult: 'Correct answer'
    },
    {
      userId: 'u103',
      fullName: 'Carlos Garcia',
      dateAnswered: '2024-03-03',
      answerResult: 'Correct answer'
    },
    {
      userId: 'u104',
      fullName: 'Maria Johnson',
      dateAnswered: '2024-03-03',
      answerResult: 'Correct answer'
    },
    {
      userId: 'u105',
      fullName: 'Li Wei',
      dateAnswered: '2024-03-03',
      answerResult: 'Correct answer'
    },
    {
      userId: 'u106',
      fullName: 'Fatima Ali',
      dateAnswered: '2024-03-03',
      answerResult: 'Correct answer'
    },
    {
      userId: 'u107',
      fullName: 'Olivia Brown',
      dateAnswered: '2024-03-03',
      answerResult: 'Correct answer'
    },
    {
      userId: 'u108',
      fullName: 'Ethan Davis',
      dateAnswered: '2024-03-03',
      answerResult: 'Correct answer'
    },
    {
      userId: 'u109',
      fullName: 'Chloe Wilson',
      dateAnswered: '2024-03-03',
      answerResult: 'Correct answer'
    },
    {
      userId: 'u110',
      fullName: 'Benjamin Martinez',
      dateAnswered: '2024-03-03',
      answerResult: 'Correct answer'
    }
  ],
  pagination: {
    currentPage: 1,
    pageSize: 10,
    totalPages: 2,
    totalUsers: 20
  }
};

export const questionedUsers = {
  quizId: '123',
  questionId: 'q1',
  questionText: 'What department you worked in?',
  totalUsersResponded: 40,
  users: [
    {
      userId: 'u101',
      fullName: 'John Doe',
      avatarUrl: 'https://api.dicebear.com/9.x/micah/svg?seed=Leo',
      dateAnswered: '2024-03-03',
      score: 'Pending',
      status: 'Pending',
      resultLink: 'https://example.com/quizzes/123/attempts/999/results'
    },
    {
      userId: 'u102',
      fullName: 'Jane Smith',
      avatarUrl: 'https://api.dicebear.com/9.x/micah/svg?seed=Katherine',
      dateAnswered: '2024-03-03',
      score: '49',
      status: 'Fail',
      resultLink: 'https://example.com/quizzes/123/attempts/1000/results'
    },
    {
      userId: 'u103',
      fullName: 'Carlos Garcia',
      avatarUrl: 'https://api.dicebear.com/9.x/micah/svg?seed=Jessica',
      dateAnswered: '2024-03-03',
      score: '50',
      status: 'Pass',
      resultLink: 'https://example.com/quizzes/123/attempts/1001/results'
    },
    {
      userId: 'u104',
      fullName: 'Maria Johnson',
      avatarUrl: 'https://api.dicebear.com/9.x/micah/svg?seed=Caleb',
      dateAnswered: '2024-03-03',
      score: '100',
      status: 'Pass',
      resultLink: 'https://example.com/quizzes/123/attempts/1002/results'
    },
    {
      userId: 'u105',
      fullName: 'Liam Brown',
      avatarUrl: 'https://api.dicebear.com/9.x/micah/svg?seed=Leo',
      dateAnswered: '2024-03-03',
      score: '90',
      status: 'Pass',
      resultLink: 'https://example.com/quizzes/123/attempts/1003/results'
    },
    {
      userId: 'u106',
      fullName: 'Emma Davis',
      avatarUrl: 'https://api.dicebear.com/9.x/micah/svg?seed=Kingston',
      dateAnswered: '2024-03-03',
      score: 'Pending',
      status: 'Pending',
      resultLink: 'https://example.com/quizzes/123/attempts/1004/results'
    },
    {
      userId: 'u107',
      fullName: 'Noah Martinez',
      avatarUrl: 'https://api.dicebear.com/9.x/micah/svg?seed=Leo',
      dateAnswered: '2024-03-03',
      score: '94',
      status: 'Pass',
      resultLink: 'https://example.com/quizzes/123/attempts/1005/results'
    },
    {
      userId: 'u108',
      fullName: 'Ava Wilson',
      avatarUrl: 'https://api.dicebear.com/9.x/micah/svg?seed=Katherine',
      dateAnswered: '2024-03-03',
      score: '45',
      status: 'Fail',
      resultLink: 'https://example.com/quizzes/123/attempts/1006/results'
    },
    {
      userId: 'u109',
      fullName: 'William Thomas',
      avatarUrl: 'https://api.dicebear.com/9.x/micah/svg?seed=Caleb',
      dateAnswered: '2024-03-03',
      score: 'Pending',
      status: 'Pending',
      resultLink: 'https://example.com/quizzes/123/attempts/1007/results'
    },
    {
      userId: 'u110',
      fullName: 'Sophia Lee',
      avatarUrl: 'https://api.dicebear.com/9.x/micah/svg?seed=Kingston',
      dateAnswered: '2024-03-03',
      score: '100',
      status: 'Pass',
      resultLink: 'https://example.com/quizzes/123/attempts/1008/results'
    }
  ],
  pagination: {
    currentPage: 1,
    pageSize: 10,
    totalPages: 4,
    totalUsers: 40
  }
};

export const userResults = {
  title: 'Design Fundamentals',
  description:
    'This quiz tests your knowledge of organizational structures and planning strategies.',
  timeTaken: 310,
  questions: [
    {
      question: 'What department did you work in?',
      type: 'Single choice',
      points: 1,
      participantAnswer: 'Financing',
      correctAnswer: 'Technology',
      isCorrect: false,
      options: [
        {
          text: 'Financing',
          isNew: false,
          id: '6279f360-3fa7-4dbe-afc5-d1cd2f4307c1',
          index: 0
        },
        {
          text: 'Technology',
          isNew: false,
          id: 'af23b1e7-cced-4fc5-9edb-1f0a1e0b8287',
          index: 1
        },
        {
          text: 'Human Resources',
          isNew: false,
          id: '9ce3534e-6763-4dfa-9c31-21b60966df2e',
          index: 2
        },
        {
          text: 'Research',
          isNew: true,
          id: 'c420fedb-aa76-495d-9115-c00d2c5e8be2',
          index: 3
        }
      ]
    },
    {
      question: 'Select all applicable departments involved in the expansion:',
      type: 'Multi choice',
      points: 1,
      participantAnswer: 'Financing, Technology',
      correctAnswer: 'Financing, Technology',
      isCorrect: true,
      options: [
        {
          text: 'Financing',
          isNew: false,
          id: '49ed036f-a037-4b32-9b32-47e3c51a6989'
        },
        {
          text: 'Technology',
          isNew: false,
          id: 'c2d8b864-78f5-40eb-a49d-7519546bfbaf'
        },
        {
          text: 'Human Resources',
          isNew: true,
          id: '5bc6e072-6d2e-4775-93d6-4b182b208f4e',
          index: 2
        },
        {
          text: 'Research',
          isNew: true,
          id: 'd3d2f15f-b268-4974-9196-212880213f04',
          index: 3
        }
      ]
    },
    {
      question: 'Which countries will be involved in this project?',
      type: 'Text',
      points: 1,
      participantAnswer: 'France and Germany',
      correctAnswer: 'France and Germany',
      isCorrect: undefined,
      options: []
    },
    {
      question: 'Choose the image that best represents project planning:',
      type: 'Picture choice',
      points: 1,
      participantAnswer: 'Option 2',
      correctAnswer: 'Option 1',
      isCorrect: false,
      options: [
        {
          text: 'Option 1',
          isNew: false,
          id: 'f9c710f5-97fc-4e4f-95ce-2f546d13a932',
          image: 'https://example.com/images/planning1.png'
        },
        {
          text: 'Option 2',
          isNew: false,
          id: '7cfcae49-4d48-4f6b-8731-aba9746b12ef',
          image: 'https://example.com/images/planning2.png'
        },
        {
          text: 'Option 3',
          isNew: true,
          id: 'a7943b92-19c8-4c0e-aedf-0220290f2f1d',
          image: 'https://example.com/images/planning3.png'
        }
      ]
    }
  ]
};

export const dummyUsers = [
  {
    id: 1,
    name: 'John Doe',
    profileImage:
      'https://cdn.wizrx.wizrapps.com/wizrx/profile/IMG-20240630-WA0015-1723555286031.jpg',
    score: 'Pending',
    status: 'Pending',
    date: '2022-01-01'
  },
  {
    id: 2,
    name: 'John Doe',
    profileImage:
      'https://cdn.wizrx.wizrapps.com/wizrx/profile/IMG-20240630-WA0015-1723555286031.jpg',
    score: 40,
    status: 'Failed',
    date: '2022-01-01'
  },
  {
    id: 3,
    name: 'John Doe',
    profileImage:
      'https://cdn.wizrx.wizrapps.com/wizrx/profile/IMG-20240630-WA0015-1723555286031.jpg',
    score: 80,
    status: 'Passed',
    date: '2022-01-01'
  },
  {
    id: 4,
    name: 'John Doe',
    profileImage:
      'https://cdn.wizrx.wizrapps.com/wizrx/profile/IMG-20240630-WA0015-1723555286031.jpg',
    score: 100,
    status: 'Passed',
    date: '2022-01-01'
  },
  {
    id: 5,
    name: 'John Doe',
    profileImage:
      'https://cdn.wizrx.wizrapps.com/wizrx/profile/IMG-20240630-WA0015-1723555286031.jpg',
    score: 90,
    status: 'Passed',
    date: '2022-01-01'
  }
];

export const fileTypes = [
  {
    id: 1,
    name: 'CSV'
  },
  {
    id: 2,
    name: 'PDF'
  },
  {
    id: 3,
    name: 'Excel'
  }
];
