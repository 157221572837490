import React from 'react';
import styles from './styles.module.scss';
import StatCard from '../StatCard';

function formatTime(totalSeconds) {
    const hours = Math.floor(totalSeconds / 3600);
    const remainder = totalSeconds % 3600;
    const minutes = Math.floor(remainder / 60);
    const seconds = remainder % 60;

    const paddedMinutes = String(minutes).padStart(2, '0');
    const paddedSeconds = String(seconds).padStart(2, '0');

    if (hours > 0) {
        return `${hours}:${paddedMinutes}:${paddedSeconds}`;
    } else {
        return `${paddedMinutes}:${paddedSeconds}`;
    }
}

export default function ResponseInfo({
    totalResponses,
    status,
    averageTime
}) {
    return (
        <div className={styles.statsContainer}>
            <StatCard value={totalResponses} label="Responses" />
            <StatCard value={formatTime(averageTime)} label="Average time to complete" />
            <StatCard value={status} label="Status" />
        </div>
    );
}
