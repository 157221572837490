import React, { useState } from 'react';
import styles from './styles.module.scss';
import DataSlider from './components/DataSlider/DataSlider';
import DataTable from './components/DataTable/DataTable';
import UserTable from './components/UserTable/UserTable';
import ResponsesHeader from './components/Header';
import ResponseInfo from './components/ResponseInfo';
import { questionedUsers, quizData } from './sampledata';
import DownloadDropdown from './components/DownloadDropdown';

export default function index() {
  const [currentDataPoint, setCurrentDataPoint] = useState(quizData.questions[0]);
  const [showDropdown, setShowDropdown] = useState(false);
  const { totalResponses, status, averageTimeToComplete } = quizData;
  const handleSlideChange = (pageNumber) => {
    setCurrentDataPoint(quizData.questions[pageNumber - 1]);
  };
  return (
    <div className={styles.container}>
      <ResponsesHeader totalResponses={totalResponses} />
      <ResponseInfo totalResponses={totalResponses} status={status} averageTime={averageTimeToComplete} />
      <DataSlider quizData={quizData} onSlideChange={handleSlideChange} />
      {currentDataPoint.questionType !== 'text_input' && <DataTable data={currentDataPoint} />}
      <UserTable data={questionedUsers} />
      <DownloadDropdown showDropdown={showDropdown} setShowDropdown={setShowDropdown} />
    </div>
  );
}
