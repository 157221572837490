import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { MdClose } from 'react-icons/md';
import { answerUsers } from '../../sampledata';

const AnswerUsersModal = ({ isOpen, setIsOpen }) => {
    const modalRef = useRef(null);

    const itemsPerPage = 5;
    const [currentPage, setCurrentPage] = useState(1);

    const { users } = answerUsers;

    const totalPages = Math.ceil(users.length / itemsPerPage);

    const paginatedUsers = users.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    const getInitials = (name) => {
        const [firstName, lastName] = name.split(' ');
        return `${firstName.charAt(0)}${lastName.charAt(0)}`;
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage((prev) => prev - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage((prev) => prev + 1);
        }
    };

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const renderPagination = () => {
        const pageNumbers = [];

        if (currentPage > 1) {
            pageNumbers.push(
                <button
                    key="prev"
                    className={styles.pageButton}
                    onClick={handlePrevPage}
                >
                    &lt;
                </button>
            );
        }

        for (let i = 1; i <= totalPages; i++) {
            pageNumbers.push(
                <button
                    key={i}
                    className={`${styles.pageButton} ${
                        currentPage === i ? styles.active : ''
                    }`}
                    onClick={() => handlePageClick(i)}
                >
                    {i}
                </button>
            );
        }

        if (currentPage < totalPages) {
            pageNumbers.push(
                <button
                    key="next"
                    className={styles.pageButton}
                    onClick={handleNextPage}
                >
                    &gt;
                </button>
            );
        }

        return pageNumbers;
    };

    return (
        <>
            {isOpen && (
                <div className={styles.modalOverlay}>
                    <div ref={modalRef} className={styles.modal}>
                        <div className={styles.header}>
                            <div>
                                <h2 className={styles.title}>1. What department you worked in?</h2>
                                <div className={styles.subtitle}>
                                    <div className={styles.dot}></div>
                                    <span>20 users respond Financing</span>
                                </div>
                            </div>
                            <button
                                className={styles.closeButton}
                                onClick={() => setIsOpen(false)}
                            >
                                <MdClose size={20} />
                            </button>
                        </div>

                        <div className={styles.hr} />

                        <div className={styles.table}>
                            <div className={styles.tableHeader}>
                                <div>Users</div>
                                <div>Date</div>
                                <div>Answer results</div>
                            </div>

                            <div className={styles.responseList}>
                                {paginatedUsers.map((response) => (
                                    <div
                                        key={response.userId}
                                        className={styles.responseItem}
                                    >
                                        <div className={styles.user}>
                                            <div className={styles.avatar}>
                                                {getInitials(response.fullName)}
                                            </div>
                                            <span>{response.fullName}</span>
                                        </div>
                                        <div className={styles.date}>
                                            {response.dateAnswered}
                                        </div>
                                        <div className={styles.status}>
                                            {response.answerResult}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className={styles.pagination}>
                            {renderPagination()}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default AnswerUsersModal;
