import React, { useEffect, useRef, useState } from 'react';
import styles from './DynamicDropdown.module.scss';
import ArrowIcon from 'assets/icons/arrowdown.svg?react';
import TickedIcon from 'assets/icons/ticked.svg?react';
import SearchIcon from 'assets/icons/search.svg?react';

const DynamicDropdown = ({
  data = [],
  label = '',
  onSelectionChange,
  search = false,
  multiSelect = false
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(data[0] || null);
  const [selectedItems, setSelectedItems] = useState(data);
  const searchRef = useRef(null);

  useEffect(() => {
    if (isOpen && searchRef.current) searchRef.current.focus();
  }, [isOpen]);

  // Filter the data only if search is enabled
  const filteredData = search
    ? data.filter((item) => {
        if (typeof item === 'string') return item.toLowerCase().includes(searchTerm.toLowerCase());
        return item.name.toLowerCase().includes(searchTerm.toLowerCase());
      })
    : data;
  const handleSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
    onSelectionChange && onSelectionChange(option);
  };
  const handleMultiSelect = (item) => {
    if (selectedItems.some((i) => i.id === item.id))
      return setSelectedItems((prev) => prev.filter((i) => i.id !== item.id));

    return setSelectedItems((prev) => [...prev, item]);
  };

  return (
    <div className={styles.dropdown}>
      <span className={styles.label}>{label}</span>
      <div
        className={`${styles.dropdownHeader} ${isOpen ? styles.focused : ''}`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{selectedOption ? selectedOption?.name : ''}</span>
        <ArrowIcon className={isOpen === true ? styles.active : styles.arrow} />
      </div>
      {isOpen && (
        <div className={`${styles.dropdownMenu} ${styles.focused}`}>
          {search && (
            <>
              <input
                type="text"
                ref={searchRef}
                className={styles.searchInput}
                placeholder="Search..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <SearchIcon className={styles.searchIcon} />
            </>
          )}
          <ul className={styles.dropdownList}>
            {multiSelect && (
              <li
                className={styles.dropdownItem}
                style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
                onClick={() =>
                  selectedItems?.length === data?.length
                    ? setSelectedItems([])
                    : setSelectedItems(data)
                }
              >
                All
                {selectedItems?.length === data?.length && <TickedIcon className={styles.ticked} />}
              </li>
            )}
            {filteredData?.map((item) => {
              if (multiSelect === false)
                return (
                  <li
                    key={item.id}
                    className={styles.dropdownItem}
                    onClick={() => handleSelect(item)}
                  >
                    {item.name}
                  </li>
                );
              return (
                <li
                  key={item.id}
                  className={`${styles.dropdownItem}`}
                  style={{ display: 'flex', alignItems: 'center', gap: '10px' }}
                  onClick={() => handleMultiSelect(item)}
                >
                  <img src={item.profileImage} alt={item.name} width={30} height={30} />
                  {item.name}
                  {selectedItems?.includes(item) && <TickedIcon className={styles.ticked} />}
                </li>
              );
            })}
            {selectedItems?.length > 0 && multiSelect && (
              <li className={`${styles.dropdownItem}`} onClick={() => setSelectedItems([])}>
                Clear
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default DynamicDropdown;
