import React, { useLayoutEffect } from 'react';
import CourseCard from 'components/atoms/CourseCard/CourseCard';
import styles from './CoursesSection.module.scss';
import { coursesData } from 'constants/courses';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCourses } from 'store/course.slice';

const filteredCourseData = coursesData.map(({  date, lessons,  ...rest }) => rest);

export default function CoursesSection() {
  const dispatch = useDispatch();
  const { courses } = useSelector((state) => state.courses);
  // replace courses above with the used data

  useLayoutEffect(() => {
    dispatch(fetchCourses());
  }, []);
  
  return (
    <div className={styles.container}>
      <div className={styles.banner}>
        <h1 className={styles.title}>All Courses</h1>
      </div>
      <div className={styles.courses}>
        {filteredCourseData?.map((d, i) => {
          return <CourseCard {...d} key={i} />;
        })}
      </div>
    </div>
  );
}
