import { useState } from 'react';
import { IoMdArrowDropdown } from 'react-icons/io';
import styles from './styles.module.scss';
import { answerTypes } from 'store/quiz.slice';

const ResultItem = ({ index, result }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const { question, participantAnswer, correctAnswer, isCorrect, options, type, points } = result;

    const {
        pictureChoice
    } = answerTypes

    const toggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className={`${styles.resultItem} ${isCorrect === undefined ? styles.unsure : (isCorrect ? styles.correct : styles.incorrect)}`}>
            <div className={styles.topContainer} onClick={toggleExpand}>
                <div className={styles.index}>{index}.</div>
                <div className={styles.question}>
                    <div className={styles.questionIcon}>
                        Q
                    </div><span className={styles.questionText}>
                        {question}
                    </span>
                </div>
                <IoMdArrowDropdown className={`${styles.arrowIcon} ${isExpanded ? styles.expanded : ''}`} />
            </div>

            {isExpanded && (
                <div>
                    <div className={styles.details}>
                        <div className={styles.answerContainer}>
                            <p className={styles.participantAnswer}>
                                <b>Your Answer:</b> {participantAnswer || 'No Answer'}
                            </p>
                            <p className={styles.correctAnswer}>
                                <b>Correct Answer:</b> {correctAnswer || 'N/A'}
                            </p>
                        </div>

                        {options && options.length > 0 && (
                            <div className={styles.optionsContainer}>
                                <p className={styles.optionsTitle}>Options:</p>
                                <div className={type === pictureChoice && styles.imgList}>
                                    {options.map((option, idx) => (
                                        <div key={idx} className={styles.optionItem}>
                                            {option.image && (
                                                <img src={option.image} alt={option.text} className={styles.optionImage} />
                                            )}
                                            <p className={styles.optionText}>{idx + 1}: {option.text}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className={styles.points}>
                        <span>{points} points</span>
                        {
                            isCorrect === undefined && <div>
                                <button className={styles.rejectBtn}>
                                    Reject answer
                                </button>
                                <button className={styles.acceptBtn}>
                                    Accept answer
                                </button>
                            </div>
                        }
                    </div>
                </div>
            )}
        </div>
    );
};

export default ResultItem;
