import React, { useLayoutEffect, useState } from 'react';
import styles from './styles.module.scss';
import { useNavigate, useParams } from 'react-router-dom';
import links from './constants';
import ResponsiveWrapper from 'components/atoms/ResponsiveWrapper/ResponsiveWrapper';
import NavTabs from 'components/atoms/NavTabs/NavTabs';
import Input from 'components/atoms/Input/Input';
import { FaImage } from 'react-icons/fa6';
import FileUploader from 'components/molecules/FileUploader/FileUploader';
import { useDispatch, useSelector } from 'react-redux';
import { setQuizDescription, setQuizTitle } from 'store/quiz.slice';
import RightMenu from './components/RightMenu';

export const defaultBannerImage =
  'https://res.cloudinary.com/dsg2ktuqk/image/upload/v1730458603/wizr%20x/d7f611fea113c02148845301a475d3bb_eohg9j.png';

export default function QuizBuilder() {
  const { tab } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {title, description} = useSelector(state => state.quiz)
  const [uploadModal, setUploadModal] = useState(false);
  const [bannerImage, setBannerImage] = useState(defaultBannerImage);

  const validTab = links.find((link) => link.path === `/quiz/${tab}`);
  const tabExceptions = ['preview']

  useLayoutEffect(() => {
    if (tabExceptions.includes(tab)) {
      navigate(`/quiz/${tab}`);
    } else if (!tab || !validTab) {
      navigate('/quiz/questions');
      return;
    }
  }, [tab, navigate, validTab]);
 
  const handleImageSelect = (imageUrl) => {
    setBannerImage(imageUrl);
    setUploadModal(false);
  };

  const handleTitleChange = (e) => {
    dispatch(setQuizTitle(e.target.value));
  }

  const handleDescChange = (e) => {
    dispatch(setQuizDescription(e.target.value));
  }

  return (
    <>
      <ResponsiveWrapper>
        <div className={styles.container}>
          <div className={styles.banner} style={{ backgroundImage: `url(${bannerImage})` }}>
            <div className={styles.inputs}>
              <div className={styles.frame}>
                <Input type="text" value={title} placeholder="Quiz Title" extraClass="titleInput" onChange={handleTitleChange} />
                <Input type="text" value={description} placeholder="Quiz description goes here" onChange={handleDescChange} />
              </div>
              <div className={styles.upload} onClick={() => setUploadModal(true)}>
                <FaImage />
              </div>
            </div>
            <NavTabs links={links}>
              <RightMenu />
            </NavTabs>
          </div>
          <main className={styles.view_container}>{validTab?.page || links[0].page}</main>
        </div>
      </ResponsiveWrapper>
      <FileUploader
        isOpen={uploadModal}
        onClose={() => setUploadModal(false)}
        onImageSelect={handleImageSelect}
      />
    </>
  );
}
