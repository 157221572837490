import React from 'react';
import styles from './styles.module.scss';
import logo from 'assets/icons/logo.svg';

export default function AttendanceCertificate() {
  return (
    <div className={styles.container}>
      <div className={styles.certificate}>
        <div className={styles.mainWrapper}>
          <img className={styles.logo} src={logo} alt="logo" />
          <h1 className={styles.title}>Certificate of completion</h1>
          <div className={styles.mainContent}>
            <p className={styles.text}>This is to certify that</p>
            <h1 className={styles.name}>Kujtesa Shabanaj</h1>
            <p className={styles.text}>
              has successfully completed Sales & Training course for 18 hours.
            </p>
          </div>
          <div className={styles.bottom}>
            <div>
              <span className={styles.subtitle}>Date:</span>
              <p className={styles.text}>21/12/2024</p>
            </div>
            <div>
              <span className={styles.subtitle}>Course author:</span>
              <p className={styles.text}>Johe Doe</p>
            </div>
          </div>
          <img className={styles.bottomLogo} src={logo} alt="logo" />
        </div>
      </div>
    </div>
  );
}
