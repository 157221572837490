import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { MdClose } from 'react-icons/md';
import QuizSummary from 'components/organisms/QuizPreview/components/QuizSummary/QuizSummary';
import { userResults } from '../../sampledata';
import ReactDOM from 'react-dom';

const ResultsModal = ({ isOpen, setIsOpen }) => {
    const modalRef = useRef(null);
    const {
        title,
        timeTaken,
        questions
    } = userResults;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside);
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen]);

    if (!isOpen) return null;

    return ReactDOM.createPortal(
        <div className={styles.modalOverlay}>
            <div ref={modalRef} className={styles.modal}>
                <div className={styles.modalHeader}>
                    <img src="https://api.dicebear.com/9.x/micah/svg?seed=Leo" className={styles.avatar} />
                    <span style={{ fontWeight: 'semi-bold', fontSize: 14 }}>John Doe</span>
                    <MdClose
                        className={styles.closeIcon}
                        onClick={() => setIsOpen(false)}
                    />
                </div>
                <QuizSummary
                    timeTaken={timeTaken}
                    title={title}
                    results={questions}
                    leftBtnFn={() => setIsOpen(false)}
                    leftBtnText="Cancel"
                    rightBtnFn={() => setIsOpen(false)}
                    rightBtnText="Save Results"
                    isReviewer={true}
                />
            </div>
        </div>,
        document.body
    );
};

export default ResultsModal;