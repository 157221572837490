import React, { useState } from 'react';
import styles from './DataTable.module.scss';
import AnswerUsersModal from '../AnswerUsers';

const DataTable = ({ data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => setIsModalOpen(true);
  return (
    <div className={styles.tableContainer}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Answers</th>
            <th>
              Responses (
              {data.totalResponses})
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data?.options?.map((item, index) => (
            <tr key={index}>
              <td>{item.optionText || 'N/A'}</td>
              <td className={styles.count}>{item.responseCount || 'N/A'}</td>
              <td className={styles.actionsColumn}>
                <button onClick={openModal}>Show users</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <AnswerUsersModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
    </div>
  );
};

export default DataTable;
