import AttendanceCertificate from 'components/organisms/AttendanceCertificate/AttendanceCertificate';
import React from 'react';

export default function Certificate() {
  return (
    <>
        <AttendanceCertificate />
    </>
  )
}
