import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { answerTypes, previewStages, setPreviewStage } from 'store/quiz.slice';
import { isAnswerCorrect } from 'helpers/quiz';
import QuizSummary from './QuizSummary';

const QuizSummaryContainer = () => {
    const { title, description, quizData, answers, timeSpent } = useSelector(state => state.quiz);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const {
        multiChoice,
        textAns,
    } = answerTypes

    const minutes = Math.floor(timeSpent / 60);
    const seconds = timeSpent % 60;
    const timeTaken = `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`

    const results = quizData.map((question, index) => {
        const userAnswer = answers[index];
        const isCorrect = isAnswerCorrect(question, userAnswer);

        const participantAnswer =
            question?.type === multiChoice
                ? userAnswer?.map(ans => ans.text).join(', ')
                : userAnswer?.text || userAnswer;

        const correctAnswer =
            question?.type === multiChoice
                ? question.answer?.[multiChoice]?.map(id =>
                    question.options.find(opt => opt.id === id)?.text
                ).join(', ')
                : question?.type === textAns ? question.answer[textAns] : question.options.find(opt => opt.id === question.answer?.[question?.type])?.text || '';

        return {
            question: question.question,
            type: question?.type,
            points: question.points,
            participantAnswer,
            correctAnswer,
            isCorrect,
            options: question.options,
        };
    });

    const gotToQuestions = () => {
        navigate('/quiz');
    }

    const gotToResults = () => {
        dispatch(setPreviewStage(previewStages.results));
    }

    return (
        <QuizSummary
            title={title}
            timeTaken={timeTaken}
            results={results}
            leftBtnFn={gotToResults}
            rightBtnFn={gotToQuestions}
            leftBtnText="Back to Results"
            rightBtnText="Back to Questions"
            blurBg={true}
        />

    );
};

export default QuizSummaryContainer;
