import { CHAT_CLIENT_URL } from 'utils/constans';

export const cardsData = [
  {
    image: 'https://cdn.wizrx.wizrapps.com/Group_40514_dd481m.png',
    title: 'share-thoughts',
    desc: 'exchange-ideas-insights-and-perspectives',
    btnText: 'go-to-news-hub',
    toLink: 'news-hub',
    newTab: false
  },
  {
    image: 'https://cdn.wizrx.wizrapps.com/Group_40514_1_ojddr0.png',
    title: 'attractive-meetings',
    desc: 'engaging-visually-appealing-sessions',
    btnText: 'go-to-WiZR-connect',
    toLink: 'wizrconnect.com',
    newTab: true
  },
  {
    image: 'https://cdn.wizrx.wizrapps.com/Group_40514_2_uihdu3.png',
    title: 'chat-anywhere',
    desc: 'providing-a-versatile-messaging-platform',
    btnText: 'go-to-chat',
    newTab: true,
    toLink: `${CHAT_CLIENT_URL}`
  }
];

export const homeContent = [
  {
    img: 'https://cdn.wizrx.wizrapps.com/Rectangle_15345_zvbqi4_jrj40s.png',
    title: 'embark-on-an',
    subtitle: 'extraordinary-journey',
    description: 'create-your-profile-and-start-connecting',
    btnText: 'create-your-profile',
    toLink: 'profile'
  },
  {
    img: 'https://cdn.wizrx.wizrapps.com/Rectangle_15342_d5bxjr_xl2zfe.png',
    title: 'effortless-team-creation-for-seamless',
    subtitle: 'collaboration',
    description: 'start-creating-your-team',
    btnText: 'create-team-profile',
    toLink: 'teams'
  }
];

export const homeContent2 = [
  {
    img: 'https://cdn.wizrx.wizrapps.com/untitled_7_2_2_2_wffe5r.png',
    title: 'the-ultimate-curation',
    subtitle: 'news-and-insights',
    description: 'start-sharing-with-others',
    btnText: 'go-to-news-hub',
    toLink: 'news-hub'
  },
  {
    img: 'https://cdn.wizrx.wizrapps.com/Rectangle_15355_w2gqfc_inlylf.png',
    title: 'info-hub-all-the-essential-info',
    subtitle: 'in-one-place',
    description: 'accessing-essential-info-of-company-easily',
    btnText: 'go-to-info-hub',
    toLink: 'info-hub'
  }
];

export const faqData = [
  {
    question: 'what-inspired-wizr-x',
    answer: 'we-wanted-to-create'
  },
  {
    question: 'how-often-should-i-use-wizr-x',
    answer: 'you-will-get-a-notification'
  },
  {
    question: 'how-do-i-edit-my-profile',
    answer: 'there-is-a-small-edit-symbol'
  },
  {
    question: 'how-do-i-use-the-buttons-in-the-profile',
    answer: 'we-created-many-options'
  }
];

export const RECOMMENDED_IMAGES = [
  'https://cdn.wizrx.wizrapps.com/with_green_ones_second_dpcybn.png',
  'https://cdn.wizrx.wizrapps.com/1633393970374-Relax%202.jpg',
  'https://cdn.wizrx.wizrapps.com/Wizy_TV_3_dyshhv.jpg',
  'https://cdn.wizrx.wizrapps.com/Studio_D_1.0_1_ueq06j.jpg',
  'https://cdn.wizrx.wizrapps.com/Office_Space_1_Concrete_zklwbi.jpg',
  'https://cdn.wizrx.wizrapps.com/Rectangle_15035_fezexu.png',
  'https://cdn.wizrx.wizrapps.com/Rectangle_15035_1_avnb7x.png',
  'https://cdn.wizrx.wizrapps.com/Rectangle_15035_2_szdvnb.png'
];

export const InfoImages = [
  'https://cdn.wizrx.wizrapps.com/Frame_41712_8_xjrmjn.png',
  'https://cdn.wizrx.wizrapps.com/Frame_40992_3_o9l0sv.png',
  'https://cdn.wizrx.wizrapps.com/Frame_41712_2_brry31.png',
  'https://cdn.wizrx.wizrapps.com/Frame_41712_6_cpyw7s.png',
  'https://cdn.wizrx.wizrapps.com/Frame_41712_1_cwh7uz.png',
  'https://cdn.wizrx.wizrapps.com/Frame_1_18_4_23_updkoc.png'
];

export const MPUBLISHER_BACKGROUNDS = [
  'https://cdn.wizrx.wizrapps.com/Rectangle_42149_2_1_hrwfwn.png',
  'https://cdn.wizrx.wizrapps.com/Rectangle_42152_2_1_uchpsr.png',
  'https://cdn.wizrx.wizrapps.com/Rectangle_42150_2_1_i2omvj.png',
  'https://cdn.wizrx.wizrapps.com/Rectangle_42157_2_1_auylee.png',
  'https://cdn.wizrx.wizrapps.com/Rectangle_42153_2_1_t9kpgz.png',
  'https://cdn.wizrx.wizrapps.com/Rectangle_42158_2_1_qosodi.png',
  'https://cdn.wizrx.wizrapps.com/Rectangle_42154_2_1_sd3icl.png',
  'https://cdn.wizrx.wizrapps.com/Rectangle_42156_2_1_xa7ttf.png',
  'https://cdn.wizrx.wizrapps.com/Rectangle_42155_2_1_f7weu4.png',
  'https://cdn.wizrx.wizrapps.com/Rectangle_42159_2_1_jamqb8.png'
];
