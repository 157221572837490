import React from 'react';
import styles from './styles.module.scss';
import DownloadI from 'assets/icons/downloadIcon.svg?react';
import PolygonI from 'assets/icons/polygon.svg?react';
import { dummyUsers, fileTypes } from '../../sampledata';
import DynamicDropdown from '../DynamicDropdown/DynamicDropdown';

const DownloadDropdown = ({ showDropdown, setShowDropdown }) => {
    return (
      <div className={styles.container}>
        <button className={styles.downloadButton} onClick={() => setShowDropdown(!showDropdown)}>
          <DownloadI />
          <span>Download</span>
          <PolygonI className={showDropdown ? styles.rotate : ''} />
        </button>
        {showDropdown && (
          <div className={styles.underlay} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
            <div className={styles.underlayContent}>
              <h3 className={styles.title}>Download</h3>
  
              <div className={styles.dropDowns}>
                <DynamicDropdown data={fileTypes} label="Select a file type" />
                <DynamicDropdown data={dummyUsers} label="Select Participants" search multiSelect />
              </div>
            </div>
            <button className={styles.submit}>Download</button>
          </div>
        )}
      </div>
    );
  };

  export default DownloadDropdown;
