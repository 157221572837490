import React from 'react';
import ResourceCard from './ResourceCard';
import Slider from 'react-slick';
import { SectionWrapper } from '..';
import Options from 'components/atoms/Options/Options';
import ResourceCreateModal from './ResourceCreateModal';
import { getS3Url, isSharedMode } from 'helpers';
import useGetSharedProfile from 'components/organisms/Profile/hooks/useGetSharedProfile';
import { useParams } from 'react-router-dom';
import { Loader, Progress } from 'components/ui';
import { TrashI } from 'components/ui';
import styles from './ResourcesSection.module.scss';
import {
  useGetProfileActiveTemplate,
  useResourcesUtils
} from 'components/organisms/Profile/hooks/templates';
import DeleteModal from 'components/molecules/DeleteModal/DeleteModal';

const defaultSectionTitle = 'Resources';

function ResourcesSectionView({ sectionTitle, resources }) {
  const paramProfileId = useParams()?.id;
  const [edit, setEdit] = React.useState(false);
  const [selectedLinks, setSelectedLinks] = React.useState([]);
  const [openDeletionModal, setOpenDeletionModal] = React.useState(false);
  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: Math.min(3, resources?.length ?? 0),
    slidesToScroll: Math.min(3, resources?.length ?? 0)
  };

  const {
    isCurrentUserProfile,
    totalUploadProgress,
    save,
    removeResources,
    openUploadModal,
    setOpenUploadModal,
    isLoading,
    isUploading
  } = useResourcesUtils({ profileId: paramProfileId });

  return (
    <>
      <SectionWrapper sectionTitle={sectionTitle ?? defaultSectionTitle}>
        {isCurrentUserProfile && (
          <Options
            extraClass="skillsOptions"
            handleDiscard={() => {
              setOpenUploadModal(false);
              setEdit(false);
            }}
            handleEdit={() => {
              setEdit(true);
            }}
            handleAdd={() => {
              setOpenUploadModal(true);
            }}
            handleSave={() => {
              setEdit(false);
            }}
            edit={edit}
          />
        )}
        {resources?.length > 0 && (
          <div
            style={{
              marginBottom: '3rem'
            }}
          >
            {isCurrentUserProfile && selectedLinks.length > 0 && (
              <div
                onClick={() => {
                  setOpenDeletionModal(true);
                }}
                className={styles['selected-awards']}
              >
                <TrashI /> <p>Delete Selected Resources</p>
              </div>
            )}

            <Progress
              height="15px"
              label="Uploading Contents..."
              total={totalUploadProgress.total}
              loaded={totalUploadProgress.loaded}
            />
            <Slider {...settings} className="resourcesSlider">
              {resources.map((resource) => (
                <ResourceCard
                  key={resource.links}
                  checked={selectedLinks.includes(getS3Url(resource.links))}
                  edit={edit}
                  onSelect={(link) => {
                    const isSelected = selectedLinks.includes(link);
                    setSelectedLinks((prev) =>
                      isSelected ? prev.filter((l) => l !== link) : [...prev, link]
                    );
                  }}
                  link={getS3Url(resource.links)}
                  type={resource?.type}
                  title={resource.title}
                />
              ))}
            </Slider>
          </div>
        )}
      </SectionWrapper>

      <ResourceCreateModal
        isLoading={isLoading ?? isUploading}
        isOpen={openUploadModal}
        onClose={() => setOpenUploadModal(false)}
        onSubmit={async ({ resourceName, resourceFile }) => save({ resourceName, resourceFile })}
      />

      {openDeletionModal && (
        <DeleteModal
          title="Are you sure you want to delete these resources ?"
          handleDelete={async () => {
            const resourcesToDelete = resources.filter((resource) =>
              selectedLinks.includes(getS3Url(resource.links))
            );
            const resultFlag = await removeResources(resourcesToDelete);
            if (resultFlag) {
              setOpenDeletionModal(false);
              setSelectedLinks([]);
            }
          }}
          handleClose={() => {
            setOpenDeletionModal(false);
          }}
        />
      )}
    </>
  );
}

function ResourcesSection({ profileId, sectionTitle }) {
  const [sharedDate] = useGetSharedProfile();
  const { profileData } = useResourcesUtils({ profileId });
  const { templateType } = useGetProfileActiveTemplate(profileId);

  const { data, isLoading, isError, error } = !isSharedMode() ? profileData : sharedDate ?? {};

  if (isLoading) return <Loader />;
  else if (isError) {
    return (
      <div
        style={{
          color: 'red'
        }}
      >
        Error: {error?.data?.message}
      </div>
    );
  }

  const resources =
    (data?.bio?.resources?.[templateType] ?? []).length !== 0
      ? data?.bio?.resources?.[templateType]
      : placeholder;
  return <ResourcesSectionView sectionTitle={sectionTitle} resources={resources} />;
}

const placeholder = [
  {
    type: 'image',
    title: 'Resource 1',
    links: 'https://cdn.wizrx.wizrapps.com/Frame_41944_t6znlu.png'
  },
  {
    type: 'image',
    title: 'Resource 2',
    links: 'https://cdn.wizrx.wizrapps.com/Frame_41943_fvimrw.png'
  },
  {
    type: 'image',
    title: 'Resource 3',
    links: 'https://cdn.wizrx.wizrapps.com/Frame_41946_wvzlba.png'
  }
];

export default ResourcesSection;
