import React, { useEffect } from 'react';
import ChevronLeft from 'assets/icons/chevronLeft.svg?react';
import ChevronRight from 'assets/icons/chevronRight.svg?react';
import styles from './DataSlider.module.scss';
import { usePagination } from 'components/organisms/UpDashboard/hooks/usePagination';

const DataSlider = ({ quizData, onSlideChange }) => {
  const itemsPerPage = 1;
  const {
    paginatedData,
    currentPage,
    hasNextPage,
    hasPreviousPage,
    navigateToPage,
    totalPages,
    pageNumbers,
  } = usePagination(quizData.questions, itemsPerPage);

  const currentQuestion = paginatedData[0];

  useEffect(() => {
    onSlideChange(currentPage);
  }, [currentPage, onSlideChange]);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    
    pageNumbers.push(
      <span
        key={1}
        className={
          currentPage === 1
            ? `${styles.pageNumber} ${styles.active}`
            : styles.pageNumber
        }
        onClick={() => navigateToPage(1)}
      >
        1
      </span>
    );

    if (currentPage > 4) {
      pageNumbers.push(
        <span key="start-ellipsis" className={styles.ellipsis}>
          ...
        </span>
      );
    }

    const startPage = Math.max(2, currentPage - 2);
    const endPage = Math.min(totalPages - 1, currentPage + 2);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <span
          key={i}
          className={
            currentPage === i
              ? `${styles.pageNumber} ${styles.active}`
              : styles.pageNumber
          }
          onClick={() => navigateToPage(i)}
        >
          {i}
        </span>
      );
    }

    if (currentPage < totalPages - 3) {
      pageNumbers.push(
        <span key="end-ellipsis" className={styles.ellipsis}>
          ...
        </span>
      );
    }

    if (totalPages > 1) {
      pageNumbers.push(
        <span
          key={totalPages}
          className={
            currentPage === totalPages
              ? `${styles.pageNumber} ${styles.active}`
              : styles.pageNumber
          }
          onClick={() => navigateToPage(totalPages)}
        >
          {totalPages}
        </span>
      );
    }

    return pageNumbers;
  };

  const renderOptions = () => {
    if (currentQuestion.questionType === 'text_input') {
      return (
        <p style={{ textAlign: 'center', margin: '20px 0px' }}>
          Can't display text answers
        </p>
      );
    }

    const total = currentQuestion.totalResponses || 1;

    if (
      currentQuestion.questionType === 'multiple_choice' ||
      currentQuestion.questionType === 'single_choice'
    ) {
      return (
        <div className={styles.answers}>
          {currentQuestion.options.map((opt) => {
            const percentage = (opt.responseCount / total) * 100;
            return (
              <div style={{ position: 'relative' }} key={opt.optionText}>
                <div className={styles.progressFill} style={{ width: `${percentage}%` }} />
                <div className={styles.answer}>
                  <span className={styles.answerLabel}>{opt.optionText}</span>
                  <span className={styles.answerValue}>{percentage}%</span>
                </div>
              </div>
            );
          })}
        </div>
      );
    }

    if (currentQuestion.questionType === 'image_choice') {
      return (
        <div className={styles.pictureAnswers}>
          {currentQuestion.options.map((opt) => {
            const percentage = (opt.responseCount / total) * 100;
            return (
              <div key={opt.optionText} className={styles.pictureAnswer}>
                <img
                  src={opt.optionImageUrl}
                  alt={opt.optionText}
                  className={styles.answerImage}
                />
                <div style={{ position: 'relative' }}>
                  <div className={styles.progressFill} style={{ width: `${percentage}%` }} />
                  <div className={styles.answer}>
                    <span className={styles.answerLabel}>{opt.optionText}</span>
                    <span className={styles.answerValue}>{percentage}%</span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  };

  return (
    <div className={styles.dataSlider}>
      <div className={styles.headingText}>
        <div className={styles.question}>
          <div>{currentPage}.</div>
          <div>{currentQuestion.questionText}</div>
        </div>
        <p className={styles.responses}>{currentQuestion.totalResponses} responses</p>
      </div>

      <button
        onClick={() => navigateToPage(currentPage - 1)}
        disabled={!hasPreviousPage}
        className={styles.arrowButton}
        style={{ left: '0px' }}
      >
        <ChevronLeft />
      </button>

      {renderOptions()}

      <button
        onClick={() => navigateToPage(currentPage + 1)}
        disabled={!hasNextPage}
        className={styles.arrowButton}
        style={{ right: '0px' }}
      >
        <ChevronRight />
      </button>

      <div className={styles.paginationControls}>
        <div className={styles.pageNumbers}>{renderPageNumbers()}</div>
      </div>
    </div>
  );
};

export default DataSlider;
