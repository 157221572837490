import HomeCard from 'components/atoms/HomeCard/HomeCard';
import React, { Suspense } from 'react';
import styles from './HomePage.module.scss';
import { cardsData, homeContent, homeContent2 } from 'constants/homeData';
import TeamSection from 'components/molecules/TeamSection/TeamSection';
import HomeSection from 'components/atoms/HomeSections/HomeSection';
import HomeFaq from 'components/molecules/HomeFaq/HomeFaq';
import { faqData } from 'constants/homeData';
import { Button, Loader } from 'components/ui';
import { useNavigate } from 'react-router-dom';
import { getCurrentUserProfileId } from 'store/login.slice';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export default function HomePage() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currentUserProfileId = useSelector(getCurrentUserProfileId);

  return (
    <Suspense fallback={<Loader fullpage={true} />}>
      <div className={styles.container}>
        <div className={styles.homeBanner}>
          <h1 className={styles.title}>{t('explore-your-community')}</h1>
        </div>
        <h1 className={styles.homeTitle}>{t('connecting-teams-through-WiZR-X')}</h1>
        <div className={styles.allCards}>
          <div className={styles.cards}>
            {cardsData.map((item, index) => {
              return (
                <HomeCard
                  key={index}
                  image={item.image}
                  title={t(item.title)}
                  desc={t(item.desc)}
                  btnText={t(item.btnText)}
                  toLink={item.toLink}
                  newTab={item.newTab}
                />
              );
            })}
          </div>
        </div>
        <TeamSection />
        <div className={styles.wrapper}>
          <div className={styles.descSection}>
            <h1 className={styles.title}>
              {t('More People')} <br /> {t('More Impact')}
            </h1>
            <p className={styles.desc}>{t('WiZR-X-is-not-just-about-features')}</p>
            <Button
              onClick={() => navigate(`/profile/${currentUserProfileId}`)}
              bgColor="#2E6055"
              color="white"
            >
              {t('create-your-profile')}
            </Button>
          </div>
          <div className={styles.infoSections}>
            {homeContent.map((d, i) => {
              return <HomeSection {...d} key={i} />;
            })}
          </div>
          <HomeSection
            btnText="News Hub"
            extraClass="mainSection"
            img="https://cdn.wizrx.wizrapps.com/r2_1_zh6rdo.png"
            title="Seamless"
            subtitle="Connections"
            description="fueling-unparalleled-collaborations"
          />
          <div className={styles.infoSections}>
            {homeContent2.map((d, i) => {
              return <HomeSection extraClass="secondSection" {...d} key={i} />;
            })}
          </div>
          <div className={styles.textContainer}>
            <div className={styles.textContent}>
              <img
                className={styles.image}
                alt="Static Image"
                src="https://cdn.wizrx.wizrapps.com/shutterstock_1667439910_j2j4lv.png"
              />
              <div className={styles.contentText}>
                <p>
                  <span>WiZR X</span>
                  {t('heart-of-the-workspace')}
                </p>
              </div>
            </div>
          </div>
          <HomeFaq faqData={faqData} />
        </div>
      </div>
    </Suspense>
  );
}
