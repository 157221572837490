import React from 'react';
import styles from './InstructorSection.module.scss';
import { Button } from 'components/ui';

export default function InstructorSection() {
  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Meet your Instructor</h1>
      <div className={styles.instructor}>
        <img
          src="https://cdn.wizrx.wizrapps.com/instructor.jpg"
          alt="instructor"
        />
        <div className={styles.info}>
          <h3 className={styles.name}>Antonio Mila</h3>
          <p className={styles.position}>Head of Painting Techniques </p>
          <Button bgColor="#434343">
            <span>+</span>Follow
          </Button>
        </div>
      </div>
    </div>
  );
}
