import React from 'react';
import styles from './styles.module.scss';
import { FiMoreVertical } from 'react-icons/fi';
import Toggle from 'components/atoms/Toggle/Toggle';

export default function ResponsesHeader({ totalResponses }) {    
    return (
        <div className={styles.header}>
            <div className={styles.leftSection}>
                <div className={styles.responsesLabel}>{totalResponses} Responses</div>
            </div>
            <div className={styles.rightSection}>
                <div className={styles.toggleWrapper}>
                    <Toggle checked={true} onChange={() => { }} />
                    <span>Accepting responses</span>
                </div>
                <button className={styles.menuButton} aria-label="More options">
                    <FiMoreVertical />
                </button>
            </div>
            
        </div>
    );
}
