export const checkoutCovers = [
  {
    id: 1,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14731_smh0vb.png'
  },
  {
    id: 2,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14732_i88prn.png'
  },
  {
    id: 3,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14733_vrq7tl.png'
  },
  {
    id: 4,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14734_fo60w8.png'
  },
  {
    id: 5,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14735_epujwt.png'
  },
  {
    id: 6,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14736_e057oz.png'
  },
  {
    id: 7,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14737_oybixz.png'
  },
  {
    id: 8,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14739_t6oikh.png'
  },
  {
    id: 9,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14741_h3mgte.png'
  }
];

export const announcementCovers = [
  {
    id: 1,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14731_dzwkld.png'
  },
  {
    id: 2,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14732_bzxtlf.png'
  },
  {
    id: 3,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14733_lbznrd.png'
  },
  {
    id: 4,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14734_i0uouy.png'
  },
  {
    id: 5,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14735_ia7qzy.png'
  },
  {
    id: 6,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14736_jbwfzm.png'
  },
  {
    id: 7,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14737_s11s6f.png'
  },
  {
    id: 8,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14739_rrnnxk.png'
  },
  {
    id: 9,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14741_oebjni.png'
  }
];

export const funCovers = [
  {
    id: 1,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14731_an0lq7.png'
  },
  {
    id: 2,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14732_usvka6.png'
  },
  {
    id: 3,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14733_xu4qzl.png'
  },
  {
    id: 4,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14734_rjbeuv.png'
  },
  {
    id: 5,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14735_qeiced.png'
  },
  {
    id: 6,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14736_u00tcd.png'
  },
  {
    id: 7,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14737_tjkgib.png'
  },
  {
    id: 8,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14739_oi4o0l.png'
  },
  {
    id: 9,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14741_p9rg14.png'
  }
];

export const updateCovers = [
  {
    id: 1,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14731_midolt.png'
  },
  {
    id: 2,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14732_k13tmy.png'
  },
  {
    id: 3,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14733_shdujj.png'
  },
  {
    id: 4,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14734_nwoqtp.png'
  },
  {
    id: 5,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14735_nhwqm6.png'
  },
  {
    id: 6,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14736_ftu3oi.png'
  },
  {
    id: 7,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14737_fxrpht.png'
  },
  {
    id: 8,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14739_lntbbk.png'
  },
  {
    id: 9,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_14741_ihcanx.png'
  }
];

export const mpublisherCovers = [
  {
    id: 1,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_42150_1_w2hwbt.png'
  },
  {
    id: 2,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_42159_1_ge0bxj.png'
  },
  {
    id: 3,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_42149_1_jkdyjx.png'
  },
  {
    id: 4,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_42153_1_sobfvz.png'
  },
  {
    id: 5,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_42158_1_gck4dz.png'
  },
  {
    id: 6,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_42152_1_ysspgq.png'
  },
  {
    id: 7,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_42154_1_xjzaaz.png'
  },
  {
    id: 8,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_42156_1_v5yf3b.png'
  },
  {
    id: 9,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_42157_1_rhqwue.png'
  },
  {
    id: 10,
    cover: 'https://cdn.wizrx.wizrapps.com/Rectangle_42155_1_vafwyk.png'
  }
];
