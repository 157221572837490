import React from 'react';
import styles from './StartGuide.module.scss';
import { useNavigate } from 'react-router-dom';
import { useTour } from '@reactour/tour';
import { steps as stepsData } from 'constants/steps';
import { getCurrentUserProfileId } from 'store/login.slice';
import { useSelector } from 'react-redux';

export default function StartGuide() {
  const currentUserProfileId = useSelector(getCurrentUserProfileId);
  const { setIsOpen, setCurrentStep, setSteps, isOpen } = useTour();

  const data = [
    {
      image: 'https://cdn.wizrx.wizrapps.com/Group_40450_1_pfzfw1.png',
      title: 'Individual Profile',
      description:
        'Invidual profile is a personal profile where you can share your information with your colleagues.',
      to: `/profile/${currentUserProfileId}`,
      clickEvent: () => setSteps([...stepsData.slice(0, 21)])
    },
    {
      image: 'https://cdn.wizrx.wizrapps.com/Group_40450_3_alfsjv.png',
      title: 'News Hub',
      description:
        'Create events for effective and more productive collaboration with your colleagues.',
      to: '/profiles',
      steps: (21, 30),
      clickEvent: () => setSteps([...stepsData.slice(0, 4), ...stepsData.slice(21, 30)])
    },
    {
      image: 'https://cdn.wizrx.wizrapps.com/Group_40451_2_tf6utb.png',
      title: 'Calendar',
      description:
        'Create events for effective and more productive collaboration with your colleagues.',
      to: '/profiles',
      steps: (30, 35),
      clickEvent: () => setSteps([...stepsData.slice(0, 4), ...stepsData.slice(30, 35)])
    }
  ];
  const navigation = useNavigate();
  return (
    <div className={styles.container}>
      <h1 className={`start-here ${styles.title}`}>Let's Create Together</h1>
      <button
        className={styles.actionBtn}
        onClick={() => {
          setIsOpen(true);
          setCurrentStep(0);
        }}
      >
        Start Tour
      </button>
      <div className={`guideCards ${styles.data}`}>
        {data?.map((item, i) => {
          return (
            <button
              onClick={() => {
                navigation(item.to);
                item.clickEvent();
              }}
              className={`click-card-${i} ${styles.card}`}
              key={i}
            >
              <img src={item.image} alt={`Step ${i + 1}`} />
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </button>
          );
        })}
      </div>
    </div>
  );
}
