
import styles from './styles.module.scss';
import ResultItem from '../ResultItem';
import { IoMdStopwatch } from 'react-icons/io';
import SummaryStats from '../SummaryStats';
import QuestionIndicators from '../QuestionIndicators';

const QuizSummary = ({
    title,
    timeTaken,
    results,
    leftBtnFn,
    rightBtnFn,
    leftBtnText,
    rightBtnText,
    blurBg = false,
    isReviewer = false
}) => {

    return (
        <div className={`${styles.quizSummaryWrapper}`}>
            <div className={`${styles.quizResultContainer} ${blurBg ? styles.blurred : styles.clear}`}>
                <div className={styles.quizResultsContainer}>
                    <div className={styles.header}>
                        <h2 className={styles.title}>{title}</h2>
                        <div className={styles.timeInfo}>
                            <IoMdStopwatch size={18} color="#999" />
                            <span>Done in {timeTaken}</span>
                        </div>
                    </div>
                    {/* <p className={styles.description}>{description}</p> */}

                    <SummaryStats results={results} />
                    <QuestionIndicators results={results} />

                    <div className={styles.separator} />

                    <div className={styles.resultsList}>
                        {results.map((result, index) => (
                            <ResultItem key={index} index={index + 1} result={result} isReviewer={isReviewer} />
                        ))}
                    </div>

                    <div className={styles.actions}>
                        <button onClick={leftBtnFn} className={styles.detailsButton}>
                            {leftBtnText}
                        </button>
                        <button onClick={rightBtnFn} className={styles.backButton}>
                        {rightBtnText}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};


export default QuizSummary;
