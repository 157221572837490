import { Button } from 'components/ui';
import React from 'react';
import styles from './TeamSection.module.scss';
import { useNavigate } from 'react-router-dom';
import { getCurrentUserProfileId } from 'store/login.slice';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export default function TeamSection() {
  const currentUserProfileId = useSelector(getCurrentUserProfileId);
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.logo}></div>
        <div className={styles.description}>
          <h1 className={styles.title}>
            {t('fostering-vibrant')}
            <br /> <span> {t('Connections')} </span> {t('and-collective-growth')}
          </h1>
          <p className={styles.desc}>{t('collaborate-and-connect-seamlessly')}</p>
          <Button
            onClick={() => {
              navigate(`/profile/${currentUserProfileId}`);
            }}
            bgColor="#2E6055"
            color="white"
          >
            {t('create-your-profile')}
          </Button>
        </div>
        <img
          className={styles.imgHolder}
          src="https://cdn.wizrx.wizrapps.com/Group_40454_1_zgvhna.png"
          alt="people"
        />
      </div>
    </div>
  );
}
